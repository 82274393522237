import { styled } from '@linaria/react';

export const Link = styled.span`
  color: var(--primary);
  font-family: var(--font-sans);
  font-size: 1rem;
  line-height: 140%;
  text-decoration-line: underline;
  cursor: pointer;
  width: fit-content;

  :hover {
    opacity: 0.9;
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
