import { ComponentProps } from 'react';

import { Link } from './RMLink.styles';

type RMLinkProps = ComponentProps<'span'> & {
  disabled?: boolean;
};

export function RMLink({ children, disabled, onClick, ...props }: RMLinkProps) {
  return (
    <Link
      data-disabled={disabled}
      onClick={(event) => {
        if (!disabled) {
          onClick?.(event);
        }
      }}
      {...props}
    >
      {children}
    </Link>
  );
}
